/**
 * Figma ID: 02-01-01
 * 名称: ログイン
 */
import React, { useEffect, useState } from 'react';

import { useGetTMemberEmailByCodeLazyQuery } from 'graphql/graphql-mw';

import { Box } from 'components/box';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/authProvider';
import { useErrorModal } from 'components/error/errorModalProvider';
import useIsMobile from 'hooks/responsive/useIsMobile';
import { Loading } from 'components/loading/loading';
import { Screen020101 } from './signIn/login';

export function SignIn() {
   /* feat_common_responsive_useMobile_logic_start */
  const isMobile = useIsMobile();
  /* feat_common_responsive_useMobile_logic_end */
  const { signIn, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { openErrorModal } = useErrorModal();
  const [getTMemberEmailByCode] = useGetTMemberEmailByCodeLazyQuery();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      // feat screen s10 start
      navigate({ pathname: !isMobile ? '/': '/s00' });
      // feat screen s10 end
    }
  // feat screen s10 start
  }, [isAuthenticated, navigate, isMobile]);
  // feat screen s10 end

  const handleSignIn = async (args: { storeCode: string; password: string }) => {
    setLoading(true);
    try {
      await signIn(args.storeCode, args.password);
    } catch (error: any) {
      switch (error.name) {
        case 'UserNotFoundException':
          openErrorModal({ message: '店舗コードは存在しません。' });
          break;
        case 'UserNotConfirmedException':
          getTMemberEmailByCode({
            context: { clientName: 'api_key' },
            variables: {
              code: args.storeCode,
            },
          })
            .then((result) => {
              if (result.data && result.data?.getTMemberEmailByCode.email) {
                navigate('/signup', {
                  state: {
                    initPhase: 'inputConfirmationCode',
                    email: result.data?.getTMemberEmailByCode.email,
                    storeCode: args.storeCode,
                  },
                });
              }
            })
            .catch(() => {
              openErrorModal({ message: '認証に失敗しました' });
            });
          break;
        case 'NotAuthorizedException':
          openErrorModal({ message: '店舗コードまたはパスワードが正しくありません。' });
          break;
        default:
          openErrorModal({ message: '認証に失敗しました' });
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    // feat_common_responsive_layout_start
    <Box width="100vw" height={!isMobile ? "100vh" : "100svh"} justifyContent="center" pt={40}>
      {loading && <Loading />}
      <Screen020101 onSignIn={handleSignIn} />
    </Box>
    // feat_common_responsive_layout_end
  );
}
