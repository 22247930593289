import { saveAs } from 'file-saver';

// 労働条件通知書PDF
export { default as createNoticeOfEmploymentPdf } from './noticeOfEmployment';
export type { NoticeOfEmploymentPdfProps } from './noticeOfEmployment';

// 源泉徴収票PDF
export { default as createWithholdingSlipPdf } from './withholdingSlip';
export type { WsPdfProps } from './withholdingSlip';

// ダウンロード処理
export const download = (buffer: Buffer | null, fileName: string) => {
  if (!buffer) return;
  // ダウンロード処理
  saveAs(new Blob([buffer], { type: 'application/pdf' }), fileName);
};

// プレビュー処理
export const preview = (buffer: Buffer | null) => {
  if (!buffer) return;
  const blob = new Blob([buffer], { type: 'application/pdf' });
  const fileUrl = URL.createObjectURL(blob);
  const isSafariOniOS =
    /iP(hone|od|ad)/i.test(navigator.userAgent) &&
    /Safari/i.test(navigator.userAgent) &&
    !/Chrome|CriOS/i.test(navigator.userAgent);

  // 別タブでPDFを表示
  if (isSafariOniOS) {
    const now = new Date();
    const fileName = `file_${now.toISOString().replace(/[-:]/g, '_').split('.')[0]}.pdf`;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(fileUrl);
  } else {
    window.open(fileUrl);
  }
};

// URLからPDFダウンロード
export const downloadFromUrl = (pdfName: string, pdfUrl: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', pdfUrl);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    saveAs(xhr.responseURL, pdfName);
  };
  xhr.send();
};
